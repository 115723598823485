.react-datepicker-wrapper {
  width: 100% !important;
}
.no-select-arrows {
  background: none;
}

.react-datepicker__input-container {
  background-color: white !important;
  box-sizing: inherit;
  margin: 0;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #fafafa;
}

.custom-select {
  display: inline-block;
  padding-left:7px;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}